import React, {Component} from "react";
import {connect} from "react-redux";
import {
  Container,
  Checkbox,
  Header,
  Button,
  Grid,
  Segment,
  Form,
} from "semantic-ui-react";
import DatePicker from "react-datepicker";
import {rescheduleBooking, updateDeleteModal} from "../../reducers/booking";
import ExamDetails from "./ExamDetails";
import "moment-timezone/builds/moment-timezone-with-data-2012-2022";
import moment from "moment-timezone";
import {withTranslation} from "react-i18next";

const MIN_TIME = "7:00";
const MAX_TIME = "23:00";

class RescheduleDates extends Component {
  /**
   *
   * @param props
   */
  constructor(props) {
    super(props);
    let date = this.props.booking.booking_date;

    this.state = {
      examDate: moment(date) || null,
      id: this.props.booking.id,
      sendRescheduleEmail: false,
    };
  }

  updateExamDate = (examDate) =>
    this.setState({
      examDate,
    });

  handleUpdateCheckbox() {
    this.setState({
      sendRescheduleEmail: !this.state.sendRescheduleEmail,
    });
  }

  render() {
    const exam = this.props.booking.exam;
    return (
      <Container>
        <Form>
          <ExamDetails
            step={this.props.step}
            examTitle={exam.title}
            duration={exam.duration || "-"}
            cost={exam.cost || 0.0}
          />
          <Form.Field>
            <Segment>
              <Header>Date & Time</Header>
              <DatePicker
                selected={this.state.examDate}
                showTimeSelect
                timeIntervals={60}
                dateFormat="MM/DD/YYYY - h:mm a"
                onChange={this.updateExamDate}
                minDate={moment()}
                maxDate={moment().add(5, "months")}
                minTime={moment(MIN_TIME, "h:mm")}
                maxTime={moment(MAX_TIME, "h:mm")}
              />
            </Segment>
            <Form.Field></Form.Field>
          </Form.Field>
          <Form.Field>
            <Segment>
              <Grid.Column width="5">
                <span style={{paddingRight: "50px"}}>
                  {this.props.t("RescheduleDates.sendUserNotification")}
                </span>
                <Checkbox
                  onClick={() => this.handleUpdateCheckbox()}
                  label=" Yes"
                  checked={this.state.sendRescheduleEmail}
                />
              </Grid.Column>
            </Segment>
          </Form.Field>
          <Button
            style={{marginBottom: "20px"}}
            onClick={() =>
              this.props.updateBooking({
                ...this.state,
                booking: this.props.booking,
              })
            }
            type="submit"
            floated="right"
            color="green">
            Submit
          </Button>
          <Button
            style={{marginBottom: "20px"}}
            onClick={() => this.props.close()}
            floated="right"
            color="red">
            Cancel
          </Button>
        </Form>
      </Container>
    );
  }
}

export default withTranslation()(
  connect(
    (state) => ({
      kc: state.keycloak,
      exam: state.exam,
    }),
    {rescheduleBooking, updateDeleteModal}
  )(RescheduleDates)
);
